
@import "bootstrap/scss/functions"; 
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

$navbar-bg-color: #343547;
$navbar-bg-hover: #4285f4;

.inspire-navbar {
  background: $navbar-bg-color;
  max-height: 200px;
  padding:0;
}

@include media-breakpoint-up(sm){
  .inspire-navbar {
    padding: 0 10% 0 10%;    
  }
  .inspire-nav{
    align-items: center!important;
  }
}

.page-title{
  border-bottom: 1px solid #ccc;
  color: grey;
}

.inspire-main {
  background-color: #EDEFF8;
  min-height: '100vh';
}
.inspire-content-reloaded{
  min-height: 86vh;
}

.inspire-breadcrumb {
  margin-top: 3em;
}

.inspire-dropdown-toggle{
  background-color: transparent;
  border: 1px solid white;
}
.inspire-dropdown-toggle:after{
  content: none !important;
}
.inspire-dropdown-toggle:hover{
  background-color: $navbar-bg-hover;
  border: 1px solid white;
  box-shadow: none;
}
.inspire-dropdown-toggle:focus{
  background-color: transparent;
  box-shadow: none;
  border: 1px solid white;
}
.inspire-dropdown-toggle:active{
  background-color: transparent !important;
  box-shadow: none;
  border: 1px solid white;
}
.inspire-dropdown-toggle:active:focus{
  background-color: transparent !important;
  box-shadow: none;
  border: 1px solid white;

}

.inspire-navbar-link {
  border: 1px solid white;
  border-radius: 5px;  
  margin: 0 0.5em 0 0.5em;
}
.inspire-navbar-link:hover {
  background-color: #4285f4;
}


.inspire-reponsive-dropdown{ 
  color: white;
  padding: 0.5em;
}

.inspire-reponsive-dropdown:hover{
  color: white;
  text-decoration: none;
  background-color: #4285f4;
}


.inspire-dropdown{
  background-color: transparent;
}
.inspire-dropdown:hover{
  background-color: $navbar-bg-hover;
}
.inspire-dropdown:focus{
  border: 0;
}
.nav-link{
  color: black;
}

// SideBar


$sidebar-width: 15rem;
$sidebar-bg-color: #343547;
$page-content-bg-color: #ECEFF8;

.hamburguer{
  width: $sidebar-width;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  background-color: transparent;
  font-size: 25px;

  color: grey;
  cursor: pointer;
}

.hamburguer-container{
  background-color: '#343547';
  margin: '0.5em';
  padding: '0.1em 0.4em 0.1em 0.4em';
  border-radius: '20px'; 
  border: '2px solid grey';
  display: 'inline-block';
}

.inspire-wrapper {
  overflow-x: hidden;
  min-height: 100vh;
  position: relative;
}

.inspire-sidebar {
  width: $sidebar-width;
  background-color: $sidebar-bg-color;

  padding-top: 5em;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3001;
  min-height: 100vh;

  margin-left: -1*$sidebar-width;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

.inspire-page-content {
  flex-grow: 1;
  background-color: $page-content-bg-color;
  overflow-y: scroll;
  height: 100vh;
  max-height: 100vh;
}

.inspire-wrapper.toggled .inspire-sidebar {
  margin-left: 0;
}

.inspire-overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 3000; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.inspire-wrapper.toggled .inspire-overlay {
  display: block; /* Hidden by default */
}

@media (min-width: 768px) {
  .inspire-sidebar {
    margin-left: 0;
    position: relative;
    
  }

  .inspire-page-content {
    min-width: 0;
  }

  .inspire-wrapper.toggled .inspire-sidebar {
    margin-left: -1*$sidebar-width;
  }

  .inspire-wrapper.toggled .inspire-overlay {
    display: none;
  }
}

.inspire-submenu-item {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

._loading_overlay_overlay{ 
  background-color: white !important;
  opacity: 0.5 !important;
  svg circle{
    stroke: black !important;
    stroke-width: 4;
  }
  
  ._loading_overlay_spinner{
    height: 80px;
    width: 80px
  }

}

