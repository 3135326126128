.inspire-table-search {
    width: 80%;
    outline: none;
    border: 0;
}

.inspire-table-search-border{
    background-color: grey;
    height: 1px;
    width: 0;
    transition: width 0.1s linear;
    &.active{
        width: 80%;    
    }
}

.inspire-table-search-number{
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
}

.inspire-table-search-number::-webkit-outer-spin-button,
.inspire-table-search-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.inspire-table tr td{
    padding: 0.5em 0 0.5em 0;
    text-align: center;
}

.inspire-table tr td:first-child{
    padding: 0.5em 0 0.5em 0;
    text-align: left;
}

.inspire-table tbody tr:hover{
    background-color: #ECEFF8;
}

.inspire-table tbody tr:hover td:first-child{    
    border-radius: 10px 0 0 10px;
}

.inspire-table tbody tr:hover td:last-child{    
    background-color: #ECEFF8;
    border-radius: 0 10px 10px 0;
}
/* Mobile table */
.inspire-mobile-table tr td{
  padding: 0.5em 0 0.5em 0;
  text-align: center;
}

.inspire-mobile-table tr td:first-child{
  padding: 0.5em 0 0.5em 0;
  text-align: left;
}

.inspire-table-subrow .expand-value{
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.inspire-table-subrow .expand-title{
    margin-top: 1em;
    color: #8a8a8a; 
    font-size: 12px;
}


.inspire-table-profile-mobile{
  .folded{
      transition: all 0.5s;
      transform: rotate(0deg);
  }
  .unfolded{
      transition: all 0.5s;
      transform: rotate(180deg);
  }
}
