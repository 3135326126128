$login-bg-color: #343547;
$navbar-bg-hover: #4285f4;

@import "bootstrap/scss/functions"; 
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.inspire-login-page{
  background-color: $login-bg-color;
  min-height: 100vh;
  border: 3px;
  width: 100vw;
  box-sizing: border-box;
}

.inspire-login-btn{
  background-color: #4A92CE;
  font-weight: bold;
  color: white;
  font-size: 16px;
  height: 55px;
  border-radius: 30px;
}

.inspire-login-btn:hover{
  border: 1px solid #DDDDDD;
  color: #EEEEEE;
}

.inspire-login-logo {
  max-width: 200px;    
  margin-bottom: 1em; 
}
@include media-breakpoint-up(sm){
  .inspire-login-logo {
    max-width: 500px;   
    margin-bottom: 3em; 
  }
}