.modal{
    opacity: 0;
}

.modal.show{
    opacity: 1;
    transition: all 0.5s;
}

.connections-modal{
    width: 80%;
    height: 90vh;
    max-width: initial;
}
.connections-modal .modal-content {
    height: 100%;
}

.company-cooperation-modal {
    width: 80%;
    height: 90vh;
    max-width: 1200px;
}
.company-cooperation-modal .modal-content {
    height: 80%;
}

.affiliations-modal {
    width: 80%;
    height: 90vh;
    max-width: 1200px;
}
.affiliations-modal .modal-content {
    height: 80%;
}

.research-profile-modal {
    width: 80%;
    height: 90vh;
    max-width: 1200px;
}
.research-profile-modal .modal-content {
    height: 80%;
}

.publications-modal {
    width: 90%;
    height: 90vh;
    max-width: 1200px;
}
.publications-modal .modal-content {
    height: 90%;
}

.events-modal {
    width: 90%;
    height: 90vh;
    max-width: 1200px;
}
.events-modal .modal-content {
    height: 90%;
}

.clinical-trials-modal {
    width: 90%;
    height: 90vh;
    max-width: 1200px;
}
.clinical-trials-modal .modal-content {
    height: 80%;
}


.inspire-table td{
    padding: 0.5em 0 0.5em 0;
    text-align: center;
}

.inspire-table tbody tr:hover{
    background-color: #ECEFF8;
}

.inspire-table tbody tr:hover td:first-child{    
    border-radius: 10px 0 0 10px;
}

.inspire-table tbody tr:hover td:last-child{    
    background-color: #ECEFF8;
    border-radius: 0 10px 10px 0;
}

.inspire-table td:empty::after{
    content: "\00a0";
}