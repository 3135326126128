$enable-prefers-reduced-motion-media-query: false;

@import "bootstrap/scss/functions"; 
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import url('https://fonts.googleapis.com/css?family=Raleway');

html, body {
  margin: 0; 
  // height: 100%; 
  // overflow: hidden
  overflow-x: hidden;
}

body {
  font-family: 'Raleway';
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
$primary-color: #282c34;
$secondary-color: #61dafb;



.App-header {
  background-color: $primary-color;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

$navbar-bg-color: #343547;
$navbar-bg-hover: #4285f4;

.inspire-navbar {
  background: $navbar-bg-color;
  max-height: 200px;
  padding:0;
}

@include media-breakpoint-up(sm){
  .inspire-navbar {
    padding: 0 10% 0 10%;    
  }
  .inspire-nav{
    align-items: center!important;
  }
}

.page-title{
  border-bottom: 1px solid #ccc;
  color: grey;
}
.page-container{
  background-color: white;
  border: 1px solid;
  border-color: #dee2e6;
  border-radius: .25rem;
  min-height: 50vh;
  padding: 2em;
}

.inspire-main {
  background-color: #EDEFF8;
  min-height: '100vh';
}
.inspire-content{
  margin-top: 3em;
  min-height: 86vh;
}

.inspire-dropdown-toggle{
  background-color: transparent;
  border: 1px solid white;
}
.inspire-dropdown-toggle:after{
  content: none !important;
}
.inspire-dropdown-toggle:hover{
  background-color: $navbar-bg-hover;
  border: 1px solid white;
  box-shadow: none;
}
.inspire-dropdown-toggle:focus{
  background-color: transparent;
  box-shadow: none;
  border: 1px solid white;
}
.inspire-dropdown-toggle:active{
  background-color: transparent !important;
  box-shadow: none;
  border: 1px solid white;
}
.inspire-dropdown-toggle:active:focus{
  background-color: transparent !important;
  box-shadow: none;
  border: 1px solid white;

}

.inspire-navbar-link {
  border: 1px solid white;
  border-radius: 5px;  
  margin: 0 0.5em 0 0.5em;
}
.inspire-navbar-link:hover {
  background-color: #4285f4;
}


.inspire-reponsive-dropdown{ 
  color: white;
  padding: 0.5em;
}

.inspire-reponsive-dropdown:hover{
  color: white;
  text-decoration: none;
  background-color: #4285f4;
}


.inspire-dropdown{
  background-color: transparent;
}
.inspire-dropdown:hover{
  background-color: $navbar-bg-hover;
}
.inspire-dropdown:focus{
  border: 0;
}
.nav-link{
  color: black;
}



.no-padding{
  padding: 0;
}
.no-margin{
  margin: 0;
}