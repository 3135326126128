$inspire-blue: #4A92CE;
$inspire-grey: #ced4da;
$inspire-text-secondary: #8A8A8A;

.inspire-box-shadow{
    -webkit-box-shadow: grey 0px 0 10px;
    -moz-box-shadow: grey 0 0 10px;
    box-shadow: grey 0 0 10px;
}

.inspire-button{
    background-color: $inspire-blue;
    border-radius: 20px;
    color: white;
    border: 1px solid transparent;
    padding: 0.4em;
}

.inspire-button:hover{
    background-color: white;
    border-radius: 20px;
    color: $inspire-blue;
    border: 1px solid $inspire-blue;
}

.inspire-ghost-button{
    background-color: white;
    border-radius: 20px;
    color: $inspire-blue;
    border: 1px solid $inspire-blue;
    padding: 0.4em;    
}
.inspire-ghost-button:hover{
    border-radius: 20px;
    background-color: $inspire-blue;
    color: white;
    border: 1px solid transparent;
}



.inspire-panel{
    background-color: white;
    border-radius: 5px;
    border: 1px solid $inspire-grey;
    padding: 1em;
    -webkit-box-shadow: grey 0px 0 10px;
    -moz-box-shadow: grey 0 0 10px;
    box-shadow: grey 0 0 10px;
}

.inspire-panel-mobile{
    background:#DEE7F4;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.5s ease;
}

.inspire-panel-mobile-active{
    border-radius: 10px;
    background-color:white;
    transition: background-color 0.5s ease;  
}

.inspire-panel-title-mobile-active{
    border-bottom: 2px solid #8A8A8A;
}

.inspire-text-secondary{
    color: $inspire-text-secondary;
}

.inspire-snapshot-section{
    border-bottom: 1px solid #8A8A8A;
    padding-bottom: 1em;
}


.nav-item .nav-link:not(.active){
    background-color: rgba(164, 200, 230, 0.2);
}

.inspire-panel-content {
    background-color: white;
    border: 1px solid;
    border-color: transparent #dee2e6 #dee2e6 #dee2e6;
    border-radius: 0 .25rem 0 .25rem;
    padding: 2em;
}

.inspire-form-control {
  border-left: 0;
  border-radius: 0 20px 20px 0;
}

.inspire-form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}


// Side Modal
.inspire-sidemodal-wrapper {
    overflow-x: hidden;
    height: 100vh;
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    margin-left: 100%;
    z-index: 4000;
    background-color: white;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}

.inspire-sidemodal-wrapper.toggled {
    margin-left: 0;
}

