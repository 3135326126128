
.tooltip{
  font-family: 'Raleway';
  .arrow{
    border: 1px solid white;
  }
  .tooltip-inner{
    background-color: #162b3d;
    border: 1px solid white;
    font-size: 15px;
  }
}
